<template>
  <div class="user_bg_view">
    <div class="menu_view">
      <div class="menu_item_view" :class="`${selectIndex == index ? 'is_active' : ''}`" v-for="(item, index) in menuList" :key="`key_${index}`" @click="selectIndex = index">
        <span>{{ item }}</span>
      </div>
    </div>
    <questionView v-if="selectIndex == 0"></questionView>
    <infoView v-if="selectIndex == 1"></infoView>
    <concernPointView v-if="selectIndex == 2"></concernPointView>
    <practiceView v-if="selectIndex == 3"></practiceView>
  </div>
</template>

<script>
export default {
  props: ['selectUserInfo'],
  components: {
    concernPointView: () => import('./components/concernPoint.vue'),
    infoView: () => import('./components/info.vue'),
    practiceView: () => import('./components/practice.vue'),
    questionView: () => import('./components/question.vue'),
  },
  data() {
    return {
      selectIndex: 0,
      menuList: ['待解决问题', '基础信息', '关注点', '购物习惯']
    };
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 1px;
}

.user_bg_view {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;

  .menu_view {
    display: flex;
    align-items: center;
    height: 44px;
    background-color: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    left: 0;

    .menu_item_view {
      height: 30px;
      width: 25%;
      text-align: center;
      font-size: 14px;
      color: #333;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        span {
          font-size: 16px;
        }
      }

      span {
        display: inline-block;
        border-bottom: 2px solid white;
      }
    }

    .is_active {
      span {
        border-bottom-color: #409EFF;
        font-size: 16px;
      }

    }
  }
}
</style>